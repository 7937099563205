class changePasswordModalComponent extends Component {

    static name() {
        return "changePasswordModalComponent";
    }

    static componentName() {
        return "changePasswordModalComponent";
    }

    getProps() {
        return  ['closeCarModal'];
    }

    getMethods() {
        return {
            close:this.close
        };
    }

    close () {
        $("#changePasswordModal").modal('close');
    }

    getTemplate() {
        return `<div id="changePasswordModal" class="modal cart-modal" >
                   <h1>Under Construcction</h1>
              </div>`;
    }
}

changePasswordModalComponent.registerComponent();